import React, { useEffect } from 'react'
import anime from 'animejs'
import style from './HomePage.module.css'

const scale: number = 10
const duration: number = 1200
const delay: number = 200

function flyWord(tl: anime.AnimeTimelineInstance, selector: string) {
  tl.add({
    targets: selector,
    opacity: 1,
    duration: 1,
  })
  tl.add({
    targets: selector,
    scale,
    duration,
    delay,
    easing: 'easeInExpo',
    opacity: 0,
  })
}

function HomePage() {
  useEffect(() => {
    const tl = anime.timeline({
      loop: true,
    })
    tl.add({
      targets: '.pas',
      delay: 400,
      duration: 1,
      opacity: 1,
    })
    flyWord(tl, '.pas')
    flyWord(tl, '.dot1')
    flyWord(tl, '.net')
    flyWord(tl, '.dot2')
    flyWord(tl, '.au')
    tl.add({
      targets: '.coming',
      delay: 1000,
      duration: 3000,
      easing: 'easeOutQuad',
      opacity: 1,
    })
    tl.add({
      targets: '.coming',
      delay: 100,
      duration: 1000,
      easing: 'easeInQuad',
      opacity: 0,
    })
    tl.add({
      duration: 1000,
    })
  })

  return (
    <div id="home" className={style.home}>
      <div className={`${style.fixed} pas`}>pas</div>
      <div className={`${style.fixed} dot1`}>dot</div>
      <div className={`${style.fixed} net`}>net</div>
      <div className={`${style.fixed} dot2`}>dot</div>
      <div className={`${style.fixed} au`}>au</div>
      <div className={`${style.fixed} ${style.coming} coming`}>Coming Soon</div>
    </div>
  );
}

export default HomePage;
